<template>
  <div class="inout-record">
    <div class="inout-record-main">
      <park-header></park-header>
      <go-back-title :title="'出入查询'"></go-back-title>
      <van-tabs v-model:active="active" @change="onTabChange" color="#999999">
        <van-tab title="车辆">
          <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefreshVh"
          >
            <van-list
              v-model:loading="isLoading"
              :finished="vhPageOption.total <= vhPageOption.list.length"
              finished-text="没有更多了"
              @load="onLoadVh"
            >
              <div class="inout-content-wrap">

                <div class="inout-date-time-header">
                  <div class="inout-record-time-picker">
                    <div @click="showDateTimePicker = true">{{currentDateTxt}}</div>
                    <div class="down-triangle" @click="showDateTimePicker = true"></div>
                    <div class="search-input" v-if="[userTypesTxt.park, userTypesTxt.groupCompany].includes(userInfo.userTypeName)">
                      <van-field size="mini"
                                 v-model="plateNumberInputVal"
                                 label="" placeholder="车牌号"
                                 right-icon="search"
                                 oninput="value=value.replace(/\s/g, '').toUpperCase()"
                                 @click-right-icon="onRefreshVh"
                      />
                    </div>
                  </div>

                  <div class="inout-total-wrap">
                    <span>出场车辆：{{vhPageOption.outTotal}}辆</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>入场车辆：{{vhPageOption.inTotal}}辆</span></div>
                </div>
                <div class="table-row"
                     @click="getDetail(item)"
                     v-for="item in vhPageOption.list">
                  <div class="inout-direction">
                    {{item.direction == 1 ? '入' : '出'}}
                  </div>
                  <div class="table-mid">
                    <div class="plate-number">{{item.vh_p_number}}</div>
                    <div>{{item.time | getDate}}</div>
                  </div>
                  <div class="table-right">
                    <div class="charge-fee">{{item | filterFee}}</div>
                    <div>车道{{item.channel_path}}</div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="分组">
          <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefreshVh"
          >
            <van-list
              v-model:loading="isLoading"
              :finished="groupPageOption.total <= groupPageOption.list.length"
              finished-text="没有更多了"
              @load="onLoadGroup"
            >
              <div class="inout-content-wrap">
                <div class="inout-date-time-header">
                  <div class="inout-record-time-picker"  @click="showDateTimePicker = true">
                    <div>{{currentDateTxt}}</div>
                    <div class="down-triangle"></div>
                    <div class="search-input" v-if="[userTypesTxt.park, userTypesTxt.groupCompany].includes(userInfo.userTypeName)">
                      <van-field size="mini"
                                 v-model="groupNameInputVal"
                                 label="" placeholder="分组名"
                                 right-icon="search"
                                 @click-right-icon="onRefreshGroup"
                      />
                    </div>
                  </div>

                  <div class="inout-total-wrap">
                    <span>出场车辆：{{groupPageOption.outTotal}}辆</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>入场车辆：{{groupPageOption.inTotal}}辆</span>
                  </div>
                </div>
                <div class="table-row"
                     @click="getDetail(item)"
                     v-for="item in groupPageOption.list">
                  <div class="inout-direction">
                    {{item.direction == 1 ? '入' : '出'}}
                  </div>
                  <div class="table-mid">
                    <div class="plate-number">{{item.vh_p_number}}</div>
                    <div>{{item.time | getDate}}</div>
                  </div>
                  <div class="table-right">
                    <div class="charge-fee">{{item | filterFee}}</div>
                    <div>车道{{item.channel_path}}</div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>




    </div>
    <div v-if="showDateTimePicker" class="bottom-date-time-picker">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="confirmCurrentDate"
        @cancel="cancelCurrentDate"
      />
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
// import * as util from '../../api/util.js'
import goBackTitle from '../../public/go-back-title'
import { mapState } from 'vuex'
export default {
  name: 'inout-record',
  components: {
    parkHeader,
    goBackTitle
  },
  data () {
    return {
      showDateTimePicker: false,
      plateNumberInputVal: '',
      groupNameInputVal: '',
      userTypesTxt: common.userTypesTxt,
      currentDate: new Date(),
      currentDateTxt: common.fmtDate(new Date(), 'yyyy年MM月'),
      formatter: (type, val) => {
        if (type === 'year') {
          return `${val}年`;
        }
        if (type === 'month') {
          return `${val}月`;
        }
        return val;
      },
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(),
      active: 0,
      isLoading: false,
      vhPageOption: {
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
        list: [],
        inTotal: 0,
        outTotal: 0
      },
      groupPageOption: {
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
        list: [],
        inTotal: 0,
        outTotal: 0
      },
      begin_time: new Date(),
      end_time: new Date()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    onRefreshGroup () {
      this.groupPageOption.page_no = 1
      this.groupPageOption.total = 0
      this.groupPageOption.list = []
      this.initList().catch(e => console.log(e))
    },
    onLoadGroup () {
      this.groupPageOption.page_no++
      this.initList().catch(e => console.log(e))
    },
    onRefreshVh () {
      this.vhPageOption.page_no = 1
      this.vhPageOption.total = 0
      this.vhPageOption.list = []
      this.initList().catch(e => console.log(e))
    },
    onLoadVh () {
      this.vhPageOption.page_no++
      this.initList().catch(e => console.log(e))
    },
    async initList() {
      try {
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_inout_list}`;
        let latestPlateNumber = localStorage.getItem('latestHistoryPlateNumber')
        let latestGroupName = localStorage.getItem('latestHistoryGroupName')
        if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
          if (this.active == 0) {
            if (latestPlateNumber && latestPlateNumber != 'undefined' && latestPlateNumber != 'null') {
              // topic += '/' + api.get_req_uid()
            } else {
              this.$toast('未查询到数据')
              this.isLoading = false
              return
            }
          } else if (this.active == 1) {
            if (latestGroupName && latestGroupName != 'undefined' && latestGroupName != 'null') {
              // topic += '/' + api.get_req_uid()
            } else {
              this.$toast('未查询到数据')
              this.isLoading = false
              return
            }
          }
        }

        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_query_inout_list] = this.getList
        let para
        if (this.active == 0) {
          para = {
            sid: localStorage.getItem('sid'),
            begin_time: this.begin_time,
            end_time: this.end_time,
            page_no: this.vhPageOption.page_no,
            page_size: this.vhPageOption.page_size
          }
          if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
            if (latestPlateNumber) {
              para.plate_number = latestPlateNumber
            } else {
              return this.$toast('没有找到需要查询的车辆')
            }
          } else if (this.plateNumberInputVal) {
            para.plate_number = this.plateNumberInputVal
          }
        } else {
          para = {
            sid: localStorage.getItem('sid'),
            begin_time: this.begin_time,
            end_time: this.end_time,
            is_group: true,
            page_no: this.groupPageOption.page_no,
            page_size: this.groupPageOption.page_size
          }
          if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
            if (latestGroupName) {
              para.group_name = latestGroupName
            } else {
              return this.$toast('没有找到需要查询的分组')
            }
          } else if (this.groupNameInputVal) {
            para.group_name = this.groupNameInputVal
          }
        }

        let res = await api.axiosRequest(api.urlList.fenglin.query.query_inout_list, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    async getList (msg) {
      console.log(msg)
      if (msg.code == common.resCode.OK) {
        if (this.active == 0) {
          this.vhPageOption.list = this.vhPageOption.list.concat(msg.list)
          this.vhPageOption.total = msg.total || 0
          this.vhPageOption.inTotal = msg.in_total || 0
          this.vhPageOption.outTotal = msg.out_total || 0
        } else if (this.active == 1) {
          this.groupPageOption.list = this.groupPageOption.list.concat(msg.list)
          this.groupPageOption.total = msg.total || 0
          this.groupPageOption.inTotal = msg.in_total || 0
          this.groupPageOption.outTotal = msg.out_total || 0
        }
      } else {
        if (this.active == 0) {
          this.vhPageOption.list = this.vhPageOption.list || []
          // this.vhPageOption.inTotal = 0
          // this.vhPageOption.outTotal = 0
        } else if (this.active == 1) {
          this.groupPageOption.list = this.groupPageOption.list || []
          // this.groupPageOption.inTotal = 0
          // this.groupPageOption.outTotal = 0
        }
      }
    },
    confirmCurrentDate (e) {
      console.log(e)
      this.showDateTimePicker = false
      this.currentDateTxt = common.fmtDate(e, 'yyyy年MM月')
      let beginEnd = common.getMonthBeginEnd(e)
      this.begin_time = beginEnd.begin_time
      this.end_time = beginEnd.end_time
      console.log(this.begin_time, this.end_time)
      if (this.active == 0) {
        this.vhPageOption.page_no = 1
        this.vhPageOption.total = 0
        this.vhPageOption.list = []
      } else {
        this.groupPageOption.page_no = 1
        this.groupPageOption.total = 0
        this.groupPageOption.list = []
      }
      this.initList().catch(e => console.log(e))
    },
    cancelCurrentDate () {
      let e = new Date()
      this.showDateTimePicker = false
      this.currentDateTxt = common.fmtDate(e, 'yyyy年MM月')
      let beginEnd = common.getMonthBeginEnd(e)
      this.begin_time = beginEnd.begin_time
      this.end_time = beginEnd.end_time
      console.log(this.begin_time, this.end_time)
      this.vhPageOption.page_no = 1
      this.initList().catch(e => console.log(e))
    },
    getDetail (row) {
      this.$router.push({
        path: '/inout_detail',
        query: {
          ...api.getQuery.apply(this),
          in_out_id: row.id
        }
      })
    },
    onTabChange (e) {
      console.log(e)
      if (e == 0) {
        if (!this.vhPageOption.list.length) {
          this.onRefreshVh()
        }
      } else if (e == 1) {
        if (!this.groupPageOption.list.length) {
          this.onRefreshGroup()
        }
      }
    },
  },
  filters: {
    getDate (time) {
      if (time) {
        return common.fmtDate(time, 'MM月dd日 hh:mm')
      } else {
        return ''
      }
    },
    filterFee (row) {
      if (row.direction == common.direction.in) {
        return ''
      }
      if (row.paidfee <= 0 || !row.paidfee) {
        return 0 + '元'
      }
      return  (row.paidfee / 100).toFixed(2) + '元'
    }
  },
  mounted () {
    new mqtt.Client()
    let nowMonth = common.getMonthBeginEnd(new Date())
    this.begin_time = nowMonth.begin_time
    this.end_time = nowMonth.end_time
    this.initList().catch(e => console.log(e))
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_inout_list}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.mq_query_inout_list]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.inout-record {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .inout-content-wrap{
      .inout-date-time-header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1rem 2rem;
        font-weight: bold;
        background: #F3FAFF;
        .inout-record-time-picker{
          width: 100%;
          display: flex;
          align-items: center;
          /*justify-content: space-between;*/
          & > .down-triangle{
            margin-left: 1rem;
            width: 0;
            height: 0;
            border-left: .6rem solid transparent;
            border-right: .6rem solid transparent;
            border-top: 1.2rem solid gray;
          }
          .search-input{
            margin-left: auto;
          }
        }

        .inout-total-wrap{
          margin-top: 1rem;
          font-size: $global-font-size-small;
          color: $global-theme-gray;
        }
      }
      .table-row{
        position: relative;
        display: flex;
        align-items: center;
        height: 8.6rem;
        background-color: white;
        /*.table-cell{*/
          /*box-sizing: border-box;*/
          /*padding: .4rem;*/
          /*flex: 1;*/
        /*}*/
        &:after{
          content: '';
          position: absolute;
          height: .1rem;
          right: 1rem;
          bottom: 0;
          left: 1rem;
          background: #F5F5F5;
        }
        .inout-direction{
          margin: auto 2rem;
            width: 3.8rem;
            height: 3.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background: $global-theme-blue;
            font-size: $global-font-size-2X-large;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }
        .table-mid{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .plate-number{
            margin-bottom: 1rem;
            font-size: $global-font-size-normal;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $global-theme-gray-333;
            /*line-height: 22px;*/

          }
          /*background: #0091FF;*/
        }
        .table-right{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          margin-left: auto;
          padding-right: 2rem;
          .charge-fee{
            margin-bottom: 1rem;
            font-size: $global-font-size-2X-large;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FF1B1B;
            line-height: 30px;
          }
        }
      }

      .van-list{
        min-height: 40rem;
      }
    }
  }
  .bottom-date-time-picker{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20
  }
  /deep/ .van-tab__text{
    font-size: $global-font-size-normal;
  }
  /deep/ .van-tab--active .van-tab__text{
    color: $global-theme-blue !important;
  }
  /deep/ .van-tabs__line{
    background-color: $global-theme-blue !important;
  }
}
</style>
